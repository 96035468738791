import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Inicio',
        component: function() {
            return import ('@/views/Home.vue')
        }
    },
    {
        path: '/artistas/:slug',
        name: 'Artista',
        component: function() {
            return import ('@/views/Artista.vue')
        }
    },
    {
        path: '/artistas',
        name: 'Artistas',
        component: function() {
            return import ('@/views/Artistas.vue')
        }
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: function() {
            return import ('@/views/Checkout.vue')
        }
    },
    {
        path: '/contacto',
        name: 'Contacto',
        component: function() {
            return import ('@/views/Contacto.vue')
        }
    },
    {
        path: '/cuenta',
        name: 'Cuenta',
        component: function() {
            return import ('@/views/Cuenta.vue')
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: function() {
            return import ('@/views/Login.vue')
        }
    },
    {
        path: '/legal',
        name: 'Legal',
        component: function() {
            return import ('@/views/Legal.vue')
        }
    },
    {
        path: '/registro',
        name: 'Registro',
        component: function() {
            return import ('@/views/Registro.vue')
        }
    },
    {
        path: '/pedido/:ref?',
        name: 'Pedido',
        component: function() {
            return import ('@/views/Pedido.vue')
        }
    },
    {
        path: '/productos/:slug',
        name: 'Producto',
        component: function() {
            return import ('@/views/Producto.vue')
        }
    },
    {
        path: '/productos',
        name: 'Productos',
        component: function() {
            return import ('@/views/Productos.vue')
        }
    },
    {
        path: '/recuperar/:slug?',
        name: 'Recuperar',
        component: function() {
            return import ('@/views/Recuperar.vue')
        }
    },
    {
        path: "*",
        name: 'Not_Found',
        component: () => import("@/views/404.vue")
    }
]

const router = new VueRouter({
    scrollBehavior (to, from, savedPosition) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ x: 0, y: 0 })
          }, 500)
        })
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router