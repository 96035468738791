import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'

Vue.use(Vuex);
 
export default new Vuex.Store({
    state: {
        cart: [],
        user: null,
        cookies: false
    }, 
    mutations: {
        changeCart: (state, connections) => {
            localStorage.setItem('cart', JSON.stringify(connections));
            state.cart = connections;
        },
        login: (state, connections) => {
            localStorage.setItem('user', connections);
            state.user = connections;
        },
        logout: (state) => {
            localStorage.removeItem('user');
            state.user = null;
        },
        aceptCookies: (state) => {
            localStorage.setItem('cookies', true);
            state.cookies = true;
        },
        initialStore(state){
            if(localStorage.getItem('cart'))
                state.cart = JSON.parse(localStorage.getItem('cart'));
            if(localStorage.getItem('user') && localStorage.getItem('user') != null){
                state.user = localStorage.getItem('user');
                axios
                    .get(process.env.VUE_APP_URL+`users/${localStorage.getItem('user')}`)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK')
                            state.user = localStorage.getItem('user');
                        else{
                            state.user = null;
                            this.$route.push('/login');
                        }
                    })
                    .catch(error => {
                        state.user = null;
                        localStorage.removeItem('user');
                        window.location = '/login';
                        //this.$route.push('/login');
                    })
            }
            if(localStorage.getItem('cookies'))
                state.cookies = localStorage.getItem('cookies');
        },
    }
});